import React from 'react'
import { Link } from 'gatsby'

import Bar from './Bar'

export default function Header({ siteTitle }) {
  return (
    <Bar color="--primary-900">
      <h1>
        <Link to="/">{siteTitle}</Link>
      </h1>
    </Bar>
  )
}

import * as React from 'react'
import styled from 'styled-components'

import { Variables } from '../styles/GlobalStyles'

const BarStyles = styled.div`
  ${Variables}

  background: var(${props => props.color || '--primary-900'});
  padding: 0.5rem 1rem;

  * {
    color: var(--text-on-color);
  }

  h1,
  h2,
  h3 {
    margin: 0 auto;
    max-width: 960px;
  }

  a {
    text-decoration: none;

    &:hover,
    &:focus {
      color: var(--text-on-color);
      text-decoration: none;
    }
  }
`

export default function Bar({ color, children }) {
  return <BarStyles color={color}>{children}</BarStyles>
}

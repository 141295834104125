import React from 'react'
import styled from 'styled-components'

const MainStyles = styled.main`
  padding: 0 1rem;

  > div {
    margin: 1.5rem auto;
    max-width: 960px;
  }
`

export default function Main({ children }) {
  return (
    <MainStyles>
      <div>{children}</div>
    </MainStyles>
  )
}

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import GlobalStyles from '../styles/GlobalStyles'
import Header from './Header'
import Footer from './Footer'

const LayoutStyles = styled.div``

export default function Layout({ children }) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <GlobalStyles />
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <LayoutStyles>{children}</LayoutStyles>
      <Footer />
    </>
  )
}

import * as React from 'react'
import styled from 'styled-components'
import { Variables } from '../styles/GlobalStyles'

const FooterStyles = styled.div`
  ${Variables}

  background: var(--primary-900);
  padding: 0.5rem;
  color: var(--text-on-color);

  .inner {
    margin: 0 auto;
    max-width: 960px;
  }
`

export default function Footer() {
  return (
    <FooterStyles>
      <div className="inner">
        © {new Date().getFullYear()}, Built with Gatsby
      </div>
    </FooterStyles>
  )
}

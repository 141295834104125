// styles/index.js
import { css, createGlobalStyle } from 'styled-components'
import resetercss from 'reseter.css/src/styled-components/js/reseter.js'

export const Variables = css`
  --primary-900: #005d1e;
  --primary-800: #007c32;
  --primary-700: #008d3d;

  --secondary-900: #8d0050;
  --secondary-800: #b20657;
  --secondary-700: #c70b5b;

  --text-on-color: #fff;
`

const GlobalStyles = createGlobalStyle`
  ${resetercss}
`
export default GlobalStyles
